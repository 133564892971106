import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import BlogPostHeader from '../components/organisms/BlogPostHeader';
import DynamicContent from '../components/organisms/DynamicContent';
import ResourceRelatedArticlesSection from '../components/organisms/ResourceRelatedArticlesSection';
import ShareResourceSection from '../components/organisms/ShareResourceSection';

const NewsItemPage = ({ data: { page } }) => {
  return (
    <Layout>
      <Seo post={page} />

      <BlogPostHeader
        title={page.title}
        date={page.date}
        returnUrl="/news"
        featuredImage={page?.featuredImage?.node}
        typeLabel="News"
      />
      <DynamicContent blocks={page.newsItem.content} />
      <ShareResourceSection backUrl="/news/" title={page.title} />
      {page.newsItem.relatedArticles && (
        <ResourceRelatedArticlesSection
          title="Related Articles"
          viewAllUrl="/news"
          resources={page.newsItem.relatedArticles}
        />
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query NewsItemQuery($id: String!) {
    page: wpNewsItem(id: { eq: $id }) {
      ...NewsItemSEO
      date
      title
      nodeType
      slug
      featuredImage {
        node {
          ...Image
        }
      }
      newsItem {
        content {
          ... on WpNewsItem_Newsitem_Content_Wysiwyg {
            content
            fieldGroupName
          }
          ... on WpNewsItem_Newsitem_Content_FullWidthImage {
            description
            fieldGroupName
            image {
              ...Image
            }
          }
          ... on WpNewsItem_Newsitem_Content_SplitTextAndImage {
            content
            fieldGroupName
            imageDescription
            image {
              ...Image
            }
          }
          ... on WpNewsItem_Newsitem_Content_FullWidthQuote {
            fieldGroupName
            quote
            author {
              name
              title
            }
          }
        }
        #   relatedArticles {
        #     ... on WpPost {
        #       title
        #       slug
        #       nodeType
        #       dateGmt
        #       featuredImage {
        #         node {
        #           ...Image
        #         }
        #       }
        #     }

        #     ... on WpGuide {
        #       title
        #       slug
        #       nodeType
        #       date
        #       featuredImage {
        #         node {
        #           ...Image
        #         }
        #       }
        #     }
        #     ... on WpNewsItem {
        #       title
        #       slug
        #       nodeType
        #       date
        #       featuredImage {
        #         node {
        #           ...Image
        #         }
        #       }
        #     }
        #     ... on WpEvent {
        #       title
        #       slug
        #       nodeType
        #       date
        #       featuredImage {
        #         node {
        #           ...Image
        #         }
        #       }
        #       event {
        #         eventDate
        #       }
        #     }
        #   }
      }
    }
  }
`;

export default NewsItemPage;
